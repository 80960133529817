import { styled } from "styled-components";
import { TableTitle } from "../Liquidity/styled";

export const BuyICOWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .img-bg-header {
    max-width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    pointer-events: none;
  }
`;

export const BuyICOContainer = styled.div`
  position: relative;
  max-width: 1440px;
  padding: 0 20px;
  padding-bottom: 40px;
  margin: auto;
  position: relative;
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
`;

export const TitlePage = styled.div`
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const WrapPageName = styled.div`
  position: relative;
`;

export const PageNameICO = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  .img-title-page {
    display: flex;
    align-items: center;
    max-width: 22px;
  }
  h1 {
    color: #a6d9ff;
    font-family: "Rethink Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.36px; /* 86.8% */
    letter-spacing: 0.28px;
  }
  @media screen and (max-width: 767px) {
    h1 {
      color: #a6d9ff;
      font-family: "Rethink Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 17.36px; /* 115.733% */
      letter-spacing: 0.28px;
    }
  }
`;

export const PageTitleICO = styled.div`
  margin-top: 15px;
  position: relative;
  width: fit-content;
  display: flex;
  .img-coin {
    position: absolute;
    width: 75px;
    top: -40px;
    right: -75px;
  }
  .text-light {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 120% */
    text-transform: uppercase;
    paint-order: stroke fill;
    margin-right: 20px;
  }
  .text-bold {
    color: #fff;
    font-family: "Rethink Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 120% */
    text-transform: uppercase;
    margin-left: -10px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
    flex-direction: column;
    .text-light {
      font-size: 36px;
      line-height: 40px; /* 111.111% */
      margin-right: 15px;
    }
    .text-bold {
      font-size: 36px;
      line-height: 40px; /* 111.111% */
    }
  }
`;

export const DescriptionsPageICO = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  .img-corner {
    max-width: 10px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const DescriptionsContentICO = styled.div`
  max-width: 570px;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  @media screen and (max-width: 1024px) {
    width: 450px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    p {
      font-size: 15px;
      line-height: 18px; /* 120% */
    }
  }
`;

export const ListSocial = styled.div`
  margin-top: 20px;
  gap: 8px;
  .img-social {
    max-width: 40px;
  }
`;

export const WrapTotalBalance = styled.div`
  position: relative;
  display: inline-flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 3px;
  margin-top: 30px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    linear-gradient(
      180deg,
      rgba(0, 63, 255, 0.1) 0%,
      rgba(40, 100, 255, 0.1) 100%
    );
  box-shadow: 0px 4.47px 8.568px 0px rgb(255, 255, 255, 0.4) inset,
    0px -4.47px 8.568px 0px rgb(255, 255, 255, 0.4) inset;
  backdrop-filter: blur(22.350000381469727px);
  @media screen and (max-width: 1024px) {
    margin-right: 0px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const TitleTotalBalance = styled.div`
  background-image: url("/assets/images/BuyICO/bg-title-right.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 300px;
  height: 110px;
  padding-left: 15px;
  padding-top: 20px;
  margin-bottom: 10px;
  h1 {
    color: #007fff;
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    text-transform: uppercase;
    margin-bottom: 3px;
  }
  h2 {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    text-transform: uppercase;
  }
  .img-iton {
    max-width: 20px;
    margin-left: 3px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const RowInfo = styled.div`
  width: 100%;
  h1 {
    color: var(--Gray-30, #c3c6cf);
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.14px;
  }
  h2 {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
  }
  .img-iton {
    max-width: 20px;
    margin-left: 3px;
  }
`;

export const TotalBalance = styled.div`
  margin-top: 30px;
  h1 {
    color: #fff;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 40px */
    letter-spacing: -2px;
    text-transform: uppercase;
  }
  .img-ton {
    max-width: 30px;
    margin-left: 5px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    p {
      font-size: 15px;
      line-height: 18px; /* 120% */
    }
  }
`;

export const OptionICO = styled.div`
  width: calc((100% - 10px) / 3);
  height: 36px;
  background-color: #393939;
  border-top-right-radius: 8px;
  border-top-left-radius: 6px;
  transform: skewX(-20deg);
  position: relative;
  left: 5px;
  top: 2px;
  transition: all.3s;
  cursor: pointer;
  p {
    color: #707070;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
    transform: skewX(20deg);
    transition: all.3s;
  }
  &:hover {
    background-color: #313131;
    p {
      color: var(--Neutral-White, #fff);
      text-align: center;
      font-family: "Rethink Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 20.4px */
      letter-spacing: -0.34px;
      transition: all.3s;
    }
  }
`;

export const SelectICO = styled.div`
  gap: 5px;
  width: 100%;
  margin-top: 20px;
  margin-right: -5px;
  padding: 0 10px;
  transition: all.3s;
  .active {
    transition: all.3s;
    background-color: #007fff;
    p {
      color: var(--Neutral-White, #fff);
      text-align: center;
      font-family: "Rethink Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 20.4px */
      letter-spacing: -0.34px;
    }
    &:hover {
      background-color: #007fff;
    }
  }
`;

export const WrapListICO = styled.div`
  position: relative;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;

export const ItemICO = styled.div`
  position: relative;
  width: calc((100% - 20px) / 3);
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #222;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const TitleItem = styled.div`
  position: relative;
  width: 100%;
  ${TableTitle} {
    p {
      font-size: 24px;
    }
    @media screen and (max-width: 1024px) {
      p {
        font-size: 20px;
        line-height: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      p {
        font-size: 20px;
      }
      .icon-header {
        max-width: 24px;
        margin-top: 3px;
      }
    }
  }
`;

export const TimeSend = styled.div`
  display: flex;
  height: 35.397px;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 7.079px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    linear-gradient(
      180deg,
      rgba(0, 63, 255, 0.1) 0%,
      rgba(40, 100, 255, 0.1) 100%
    );
  box-shadow: 0px 3.956px 7.582px 0px rgba(255, 255, 255, 0.3) inset,
    0px -3.956px 7.582px 0px rgba(255, 255, 255, 0.3) inset;
  backdrop-filter: blur(19.778076171875px);
  h1 {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 16.25px */
  }
  h2 {
    color: #aaef27;
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 16.25px */
  }
`;

export const WrapInfo = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: ${({ isActive }) => (isActive ? "0.493px solid #bbf7c6" : "")};
  background: ${({ isActive }) =>
    isActive ? "linear-gradient(180deg, #25376e 0%, #0043ec 100%)" : "#3B3B3B"};
  box-shadow: ${({ isActive }) =>
    isActive
      ? "-1.972px 1.972px 2.835px 0px rgba(255, 255, 255, 0.3) inset, 1.972px -1.972px 2.835px 0px rgba(255, 255, 255, 0.3) inset"
      : ""};
`;

export const ListTotal = styled.div`
  position: relative;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const TotalItem = styled.div`
  width: calc((100% - 8px) / 2);
  padding: 8px;
  padding-left: 15px;
  gap: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0px -3px 0px 0px rgba(34, 34, 34, 0.25) inset,
    0px 3px 0px 0px rgba(186, 186, 186, 0.25) inset;
  .img-total {
    max-width: 25px;
  }
  .img-iton {
    max-width: 20px;
    margin-left: 4px;
  }
  h2 {
    color: var(--Gray-30, #c3c6cf);
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.13px;
  }
  h1 {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
    margin-top: 3px;
  }
  @media screen and (max-width: 1024px) {
    &:nth-child(3) {
      width: 100%;
    }
  }
`;

export const TokenSaled = styled.div`
  width: 100%;
  h1 {
    color: var(--Neutral-100, #eeeef0);
    font-family: "Rethink Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
  .percent {
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: rgba(33, 144, 255, 0.5);
    p {
      color: #addaff;
      text-align: center;
      font-family: "Rethink Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 16.5px */
    }
  }
`;

export const Percents = styled.div<{ percent: number }>`
  width: 100%;
  height: 17px;
  border-radius: 50px;
  position: relative;
  margin-top: -3px;
  .img-blur {
    max-width: 50px;
    position: absolute;
    left: ${({ percent }) => (percent ? `${percent - 7}%` : "-22px")};
    top: -15px;
    z-index: 0;
  }
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    linear-gradient(
      180deg,
      rgba(0, 63, 255, 0.1) 0%,
      rgba(40, 100, 255, 0.1) 100%
    );
  box-shadow: 0px 4.47px 8.568px 0px rgba(255, 255, 255, 0.3) inset,
    0px -4.47px 8.568px 0px rgba(255, 255, 255, 0.3) inset;
  backdrop-filter: blur(22.350000381469727px);
  h1 {
    color: var(--Neutral-50, #f7f7f8);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 13.75px */
    position: absolute;
    width: 100%;
    z-index: 2;
    padding-top: 2px;
    span {
      color: rgba(255, 255, 255, 0.5);
      font-family: "Rethink Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: ${({ percent }) => (percent ? `${percent}%` : "")};
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ),
      linear-gradient(
        180deg,
        rgba(0, 63, 255, 0.2) 0%,
        rgba(40, 100, 255, 0.2) 100%
      );
    backdrop-filter: blur(22.350000381469727px);
    box-shadow: 0px 4.47px 8.568px 0px rgba(255, 255, 255, 0.3) inset,
      0px -4.47px 8.568px 0px rgba(255, 255, 255, 0.3) inset;
  }
`;

export const WrapSelectToken = styled.div`
  display: flex;
  height: 157px;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 767px) {
    height: 170px;
  }
`;

export const RowSelectWallet = styled.div`
  padding: 10px 10px;
  border-radius: 20px;
  background-color: rgba(128, 194, 255, 0.25);
  margin-bottom: 10px;
  position: relative;
  .input-select-wallet {
    position: absolute;
    z-index: 3;
    padding: 5px;
    width: 170px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const SelectWallet = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 20px;
  width: 170px;
  position: relative;
  z-index: 2;
  .img-arrow {
    max-width: 30px;
    margin-top: -3px;
    transition: all.3s;
  }
  h2 {
    color: #255191;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    font-weight: bold;
  }
  @media screen and (max-width: 767px) {
    width: 140px;
    h2 {
      font-size: 12px;
    }
  }
`;
export const OptionsWallet = styled.div`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  border-radius: 15px;
  background-color: #fff;
  padding: 5px;
  top: 45px;
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: all.3s;
  overflow: hidden;
`;

export const OptionWallet = styled.div<{isshow?:any}>`
  border-radius: 15px;
  min-width: 160px;
  padding: 5px 15px;
  transition: all.3s;
  h2 {
    color: #0059aa;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    font-weight: bold;
    transition: all.3s;
  }
  &:hover {
    background-color: rgb(0, 127, 255);
    transition: all.3s;
    h2 {
      color: #fff;
      transition: all.3s;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 12px;
    }
  }
`;

export const TitleSelectToken = styled.div`
  width: 100%;
  .img-title {
    max-width: 20px;
    margin-left: 5px;
  }
  h1 {
    color: #a6d9ff;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.36px; /* 124% */
    margin-left: 5px;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 12px;
    }
  }
`;

export const Balance = styled.div`
  width: 100%;
  .row-left {
    h1 {
      color: rgba(255, 255, 255, 0.5);
      font-family: "Rethink Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 35px */
      margin-right: 5px;
    }
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
    background-color: transparent;
    &::placeholder {
      font-size: 27px;
      color: rgba(255, 255, 255, 0.3);
      font-weight: 400;
    }
  }
  h1 {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 35px */
  }
  h2 {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 13.662px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.395px */
    text-transform: uppercase;
  }
  .row-between {
    width: 100%;
  }
  p {
    color: #a6d9ff;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.36px; /* 124% */
    cursor: pointer;
  }
`;

export const StatusForm = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(101, 173, 255, 0.16);
  backdrop-filter: blur(2.9000000953674316px);
  padding: 5px;
  .img-protect {
    max-width: 45px;
  }

  p {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
`;

export const StatusFormLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BtnSubmit = styled.div`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #007fff;
  box-shadow: 0px -2.562px 0px 0px rgba(34, 34, 34, 0.25) inset,
    0px 2.562px 0px 0px rgba(186, 186, 186, 0.25) inset;
  cursor: pointer;
  width: 100%;
  position: relative;
  p {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
  }
  .img-line {
    width: 100%;
    position: absolute;
    bottom: -10px;
  }
  .img-icon {
    max-width: 15px;
    margin-left: 5px;
  }
`;

export const TotalBalanceFt = styled.div`
  margin-top: 10px;
  background-image: url("/assets/images/BuyICO/bg-status.png");
  background-position: center;
  background-size: 100% 100%;
  height: 120px;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  h1 {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    text-transform: uppercase;
  }
  h2 {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-family: "Rethink Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 40px */
    letter-spacing: -2px;
    text-transform: uppercase;
  }
  .img-icon {
    max-width: 30px;
    margin-left: 5px;
    margin-top: 3px;
  }
`;

export const TokenInvestWrap = styled.div`
  margin-top: 50px;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
    ${PageTitleICO} {
      flex-direction: row;
    }
  }
`;

export const TokenInvestTable = styled.div`
  overflow-x: hidden;
  width: 1400px;
  margin-top: 10px;
  border-top-right-radius: 6px;
`;

export const WrapInvestTable = styled.div`
  overflow-x: scroll;
  width: 100%;
  border-top-right-radius: 6px;
`;

export const TableInvestHead = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1200px;
  flex-shrink: 0;
  padding: 10px 15px;
  align-self: stretch;
  border-radius: 6px 6px 0px 0px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    linear-gradient(
      180deg,
      rgba(0, 63, 255, 0.1) 0%,
      rgba(40, 100, 255, 0.1) 100%
    );
  box-shadow: 0px 4.47px 8.568px 0px rgb(255, 255, 255, 0.3) inset,
    0px -4.47px 8.568px 0px rgb(255, 255, 255, 0.3) inset;
  backdrop-filter: blur(22.350000381469727px);
  p {
    color: var(--Blue--Pri-50, #edfaff);
    text-align: left;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.83px; /* 163.735% */
  }
`;

export const TableInvestRow = styled.div`
  display: flex;
  padding: 15px 15px;
  align-items: center;
  align-self: stretch;
  background: #222;
  width: 100%;
  min-width: 1200px;
  h1 {
    color: #bbe4ff;
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 100.218% */
  }
  p {
    color: var(--Neutral-White, #fff);
    font-family: "Rethink Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 100.218% */
  }
`;

export const TableInvestCell = styled.div`
  display: flex;
  align-items: center;
`;
