import {
  BuyICOContainer,
  BuyICOWrap,
  DescriptionsContentICO,
  DescriptionsPageICO,
  ListSocial,
  OptionICO,
  PageNameICO,
  PageTitleICO,
  RowInfo,
  SelectICO,
  TitlePage,
  TitleTotalBalance,
  WrapListICO,
  WrapPageName,
  WrapTotalBalance,
} from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { useContext, useEffect, useState , useLayoutEffect } from "react";
import ElementICO from "./ItemICO";
import { useGetAssetsQuery } from "../../store/api/dexApiSlice";
import { Coins } from "ton3-core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import TokenInvest from "./tokenInvest";
import { instance } from "../../services/instance";
import dayjs from "dayjs";
import { convertFixed } from "../../utils/convertFormatNumber";
import _ from "lodash";
import { useTonAddress } from "@tonconnect/ui-react";

const TIME_SALE = {
  hour: 14,
  angel: {
    start: "10/26/2024",
  },
  seed: {
    start: "11/12/2024",
  },
  public: {
    start: "12/01/2024",
  },
};

export default function BuyICOPage() {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const { data: assets } = useGetAssetsQuery();
  const { balanceList }: any = useSelector((state: any) => state.assets);
  const address = useTonAddress();

  const [totalTon, setTotalTon]: any = useState();
  const [tonHoldingBalance, setTonHoldingBalance]: any = useState(0);
  const [listPackage, setListPackage]: any = useState(LIST_ICO);
  const [typeSelected, setTypeSelected] = useState("angel_investor");
  const [balanceIternal, setBalanceIternal] = useState(0)

  const handleSelectType = (type: string) => {
    setTypeSelected(type);
  };

  const handleGetBalanceOfRound = async () => {
    try {
      if (!address) return;
      let token = null;
      const intervalCheckToken = setInterval(async () => {
        token = localStorage.getItem("token");
        if (token) {
          clearInterval(intervalCheckToken);
          const { data } = await instance.get(`presale/tonholding-balance`);
          setTonHoldingBalance(data?.balance_main_wallet);
          setBalanceIternal(data?.balance_internal_wallet)
        }
      }, 1000);
      if (token) {
        clearInterval(intervalCheckToken);
      }
    } catch (error) {
      console.log("handleGetBalanceOfRound err", error);
    }
  };

  const handleGetPackageReSale = async () => {
    try {
      const { data } = await instance.get(`/presale/rounds`);
      let LIST_REFRESH = LIST_ICO;
      if (!address) {
        LIST_REFRESH = LIST_ICO.map((item: any) => ({
          ...item,
          id: null,
        }));
      }
      const reduceData = data.map((item: any, index: number) => ({
        ...LIST_REFRESH[index],
        ...item,
        title: item?.name,
        timeStart: `${dayjs.unix(item?.start_date).format("D, MMM, YYYY")} - ${TIME_SALE.hour
          }:00 (UTC)`,
        timeEnd: item?.end_date && item?.end_date !== "Unlimited" ? `${dayjs.unix(item?.end_date).format("D, MMM, YYYY")} - ${TIME_SALE.hour
          }:00 (UTC)` : "Unlimited",
        maxBuy: `$${convertFixed(item?.max_buy)}`,
        tokenSended: item?.name === "ANGEL INVESTOR" ? `$${convertFixed(Number(item?.total_saled) + 270000)}` : item?.name === "SEED ROUND" ? `$${convertFixed(item?.total_saled + 1000000)}` : `$${convertFixed(item?.total_saled)}`,

        // item?.name === "ANGEL INVESTOR" &&
        // item?.total_saled &&
        // item?.total_saled < item?.total_raise - 100000
        //   ? `$${convertFixed(item?.total_saled + 280000)}`
        //   : item?.name === "SEED ROUND" &&
        //     item?.total_saled < item?.total_raise - 100000
        //   ? `$${convertFixed(item?.total_saled + 1100000)}`
        //   : `$${convertFixed(item?.total_saled)}`,
        total_saled: item?.name === "ANGEL INVESTOR" ? Number(item?.total_saled) + 270000 : item?.name === "SEED ROUND" ? Number(item?.total_saled) + 1000000 : item?.total_saled,
        // item?.name === "ANGEL INVESTOR" &&
        // item?.total_saled && item?.total_saled < item?.total_raise - 100000 ? item?.total_saled + 280000
        // : item?.name === "SEED ROUND" && item?.total_saled < item?.total_raise - 100000 ? item?.total_saled + 1100000
        // : item?.total_saled,
        totals: [
          {
            label: "Total Raise",
            value: `$${convertFixed(item?.total_raise)}`,
            img: "/assets/images/BuyICO/img-around.png",
          },
          {
            label: "Price",
            value: `$${convertFixed(item?.price)}`,
            img: "/assets/images/BuyICO/img-flower.png",
          },
        ],
      }));
      setListPackage(_.sortBy(reduceData, "start_date"));
    } catch (error) {
      console.log("handleGetPackageReSale err", error);
    }
  };

  const onRefreshData = () => {
    try {
      handleGetPackageReSale();
      handleGetBalanceOfRound();
    } catch (error) {
      console.log("onRefreshData err", error);
    }
  };

  useEffect(() => {
    if (balanceList && assets) {
      const balance = Number(
        Coins.fromNano(
          balanceList[`${process.env.REACT_APP_TON_ADDRESS}`]
            ? balanceList[`${process.env.REACT_APP_TON_ADDRESS}`]
            : 0,
          assets[`${process.env.REACT_APP_TON_ADDRESS}`]?.decimals
            ? assets[`${process.env.REACT_APP_TON_ADDRESS}`]?.decimals
            : 9
        )
      );
      setTotalTon(balance);
    }
  }, [assets, balanceList]);
  
  useLayoutEffect(()=>{
    window.location.href = "https://exchange.iton.co/buy-ido"
  },[])
  useEffect(() => {
    onRefreshData();
  }, [address]);

  return (
    <BuyICOWrap className=" animate__fadeIn">
      {/* <BuyICOContainer>
        <TitlePage className="row-between">
          <WrapPageName className=" animate__fadeInLeft">
            <PageNameICO>
              <div className="img-title-page">
                <img
                  src={"/assets/images/landing/icon-title-page.webp"}
                  alt="title"
                />
              </div>
              <h1>IDO Pool</h1>
            </PageNameICO>
            <PageTitleICO>
              <p className="text-light">iTON</p>
              <p
                className="text-bold"
                style={{
                  marginLeft: isMobile ? "0px" : "",
                }}
              >
                {!isMobile && "–"} Unlock the Future
              </p>
            </PageTitleICO>
            <PageTitleICO
              style={{
                marginTop: "0px",
                paddingLeft: "10px",
              }}
            >
              <p className="text-bold"> of DeFi on TON Blockchain</p>
            </PageTitleICO>
            <DescriptionsPageICO>
              <div className="img-corner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path d="M0 10V0H10L0 10Z" fill="#0094FF" />
                </svg>
              </div>
              <DescriptionsContentICO>
                <p>
                  iTON is an integrated platform that allows users to
                  participate in various activities on the TON Blockchain. iTON
                  optimizes user experience in Trading, Provide Liquidity &
                  Farming, Staking and Participating in DeFi activities.
                </p>
                <ListSocial className="row-left">
                  {LIST_SOCIAL.map((item: any, index: number) => (
                    <Tooltip key={index} title={item.label}>
                      <Link
                        className="img-social row-center"
                        to={item.link}
                        target="_blank"
                      >
                        <img src={item.img} alt="img" />
                      </Link>
                    </Tooltip>
                  ))}
                </ListSocial>
              </DescriptionsContentICO>
            </DescriptionsPageICO>
          </WrapPageName>
          <WrapTotalBalance className=" animate__fadeInRight">
            <TitleTotalBalance>
              <h1>TOTAL SUPPLY</h1>
              <div className="row-left">
                <h2>500,000,000</h2>
                <div className="img-iton row-center">
                  <img src="/assets/images/landing/logo-lg.webp" alt="logo" />
                </div>
              </div>
            </TitleTotalBalance>
            <RowInfo className="row-between">
              <h1>Token name</h1>
              <h2>iTON</h2>
            </RowInfo>
            <RowInfo className="row-between">
              <h1>Symbol</h1>
              <div className="row-left">
                <h2>iTON</h2>
                <div className="img-iton row-center">
                  <img src="/assets/images/landing/logo-lg.webp" alt="logo" />
                </div>
              </div>
            </RowInfo>
            <RowInfo className="row-between">
              <h1>Blockchain</h1>
              <h2>TON Blockchain</h2>
            </RowInfo>
            <RowInfo className="row-between">
              <h1>Total Supply</h1>
              <h2>500,000,000</h2>
            </RowInfo>
          </WrapTotalBalance>
        </TitlePage>

        {isMobile && (
          <SelectICO className="row-center">
            {OPTIONS.map((item: any, index: number) => (
              <OptionICO
                onClick={() => {
                  handleSelectType(item.key);
                }}
                className={
                  typeSelected === item.key ? "row-center active" : "row-center"
                }
                key={index}
              >
                <p>{item.label}</p>
              </OptionICO>
            ))}
          </SelectICO>
        )}
        <WrapListICO className=" animate__fadeInUp row-center">
          {listPackage.map((item: any, index: number) =>
            isMobile && typeSelected === item.key ? (
              <ElementICO
                key={index}
                itemICO={item}
                balanceTonWallet={totalTon}
                balanceTonHolding={tonHoldingBalance}
                balanceIternal={balanceIternal}
                onBuyICO={onRefreshData}
              />
            ) : !isMobile ? (
              <ElementICO
                key={index}
                itemICO={item}
                balanceTonWallet={totalTon}
                balanceTonHolding={tonHoldingBalance}
                balanceIternal={balanceIternal}
                onBuyICO={onRefreshData}
              />
            ) : (
              <></>
            )
          )}
        </WrapListICO>
        <TokenInvest />
      </BuyICOContainer> */}
    </BuyICOWrap>
  );
}

const LIST_SOCIAL = [
  {
    label: "Channel",
    img: "/assets/images/BuyICO/icon-tele.png",
    link: "https://t.me/iTONOfficial",
  },
  {
    label: "Twitter",
    img: "/assets/images/BuyICO/icon-x.png",
    link: "https://x.com/iTONExchange",
  },
  {
    label: "Youtube",
    img: "/assets/images/BuyICO/icon-yt.png",
    link: "https://www.youtube.com/@iTONExchange",
  },
  {
    label: "Medium",
    img: "/assets/images/BuyICO/icon-me.png",
    link: "https://medium.com/@itonexchange",
  },
  {
    label: "Group",
    img: "/assets/images/BuyICO/icon-tele.png",
    link: "https://t.me/iTONExchange",
  },
];

const OPTIONS = [
  {
    label: "Angel Investor",
    key: "angel_investor",
  },
  {
    label: "Seed Round",
    key: "seed_round",
  },
  {
    label: "Public Sale",
    key: "public_sale",
  },
];

const LIST_ICO = [
  {
    key: "angel_investor",
    title: "Angel INVESTOR",
    maxBuy: "$20,000",
    timeStart: "26/10/2024 - 14:00 (UTC)",
    timeEnd: "11/11/2024 - 14:00 (UTC)",
    totals: [
      {
        label: "Total Raise",
        value: "$306,250",
        img: "/assets/images/BuyICO/img-around.png",
      },
      {
        label: "Price",
        value: "$0.0175",
        img: "/assets/images/BuyICO/img-flower.png",
      },
    ],
    tokenSended: "0",
  },
  {
    key: "seed_round",
    title: "SEED ROUND",
    maxBuy: "$2,000",
    timeStart: "12/11/2024 - 14:00 (UTC)",
    timeEnd: "30/11/2024 - 14:00 (UTC)",
    totals: [
      {
        label: "Total Raise",
        value: "$1,200,000",
        img: "/assets/images/BuyICO/img-around.png",
      },
      {
        label: "Price",
        value: "$0.03",
        img: "/assets/images/BuyICO/img-flower.png",
      },
    ],
    tokenSended: "0",
  },
  {
    key: "public_sale",
    title: "PUBLIC SALE",
    maxBuy: "$200",
    timeStart: "01/12/2024 - 14:00 (UTC)",
    timeEnd: "14/12/2024 - 14:00 (UTC)",
    totals: [
      {
        label: "Total Raise",
        value: "$1,050,000",
        img: "/assets/images/BuyICO/img-around.png",
      },
      {
        label: "Price",
        value: "$0.042",
        img: "/assets/images/BuyICO/img-flower.png",
      },
    ],
    tokenSended: "0",
  },
];
